import { Box } from "@chakra-ui/react";
import React from "react";

const UpcomingEvents = () => {
  return (
    <Box className="h-[219px] w-[355px] rounded-lg mr-24 ">
      <Box className="text-[#16222C] font-semibold text-xl">
        Upcoming Events
      </Box>

      <input type="checkbox" id="1" className=" h-4 w-4" />
      <label className=" ml-2">Classes</label>
      <br></br>
      <input type="checkbox" id="2" className=" h-4 w-4 mt-4" />
      <label className=" ml-2">Mock Tests</label>
      <br></br>
      <input type="checkbox" id="3" className=" h-4 w-4 mt-4" />
      <label className=" ml-2">Challenges</label>
      <br></br>
      <input type="checkbox" id="3" className=" h-4 w-4 mt-4" />
      <label className=" ml-2">Personal</label>
    </Box>
  );
};

export default UpcomingEvents;

import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { MessagesSquare, ShieldCheck } from "lucide-react";
import PostFormSection from "./postFormSection";
import { useSelector } from "react-redux";
import SuggestionSection from "./suggestionSection";
import ChallengeForm from "./challenge/challengesPostCard/challengeForm";
import ChallengeList from "./challenge/challengeList";

import ChallengesModal from "./challenge/challengesModals/challengesModal";
import DiscussionModal from "./challenge/challengesModals/discussionModal";
import { useDisclosure } from "@chakra-ui/react";
import { FeedPostList } from "./discussion/feedPostList";
import LatestVideosSlider from "./latestVideos/latestVideosSlider";
import { getPeopleSuggestions } from "@/api/events";

const FeedTabsSection = () => {
  const { _id: uid } = useSelector((state) => state.userData);

  const {
    isOpen: isOpenChallenge,
    onOpen: onOpenChallenge,
    onClose: onCloseChallenge,
  } = useDisclosure();

  const {
    isOpen: isOpenDiscussion,
    onOpen: onOpenDiscussion,
    onClose: onCloseDiscussion,
  } = useDisclosure();

  const [clickPhoto, setClickPhoto] = useState("");
  const finalRef = useRef(null);
  const [selectedComponent, setSelectedComponent] = useState("text");

  const {
    isLoading: suggestionLoading,
    data: suggestionData,
    isError: suggestionIsError,
    error: suggestionError,
    isPending: suggestionIsPending,
    isSuccess: suggestionIsSuccess,
  } = useQuery({
    queryKey: ["getPeopleSuggestion", uid],
    queryFn: () => getPeopleSuggestions(uid),
    onError: (error, variables, context) => {
      console.log(error);
    },
    onSuccess: (res) => {
      console.log("home suggestion data", res);
    },
  });

  const handleDiscussionModalClose = () => {
    onCloseDiscussion();
    // console.log("checking close", selectedComponent);
    setSelectedComponent("text"); // Update state when modal is closed
  };

  return (
    <>
      <ChallengesModal
        isOpen={isOpenChallenge}
        onClose={onCloseChallenge}
        finalRef={finalRef}
        triggeredFrom="user"
      />
      <DiscussionModal
        isOpen={isOpenDiscussion}
        onClose={handleDiscussionModalClose}
        clickPhoto={clickPhoto}
        finalRef={finalRef}
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        triggeredFrom="user"
      />
      <Box mt={{ base: "6", md: "10" }}>
        <Tabs isFitted>
          <TabList>
            <Tab
              borderTopLeftRadius={9}
              bg="#fff"
              _selected={{ color: "#000", borderBottom: "2px solid black" }}
              color="#8D96A5"
              fontSize={{ base: "16px", md: "20px" }}
              fontWeight="600"
            >
              <HStack py={{ base: "3", md: "4" }}>
                <MessagesSquare />
                <Text fontSize={{ base: "12px", md: "16px" }}>Discussion</Text>
              </HStack>
            </Tab>
            <Tab
              borderTopRightRadius={9}
              bg="#fff"
              _selected={{ color: "#000", borderBottom: "2px solid black" }}
              color="#8D96A5"
              fontSize={{ base: "16px", md: "20px" }}
              fontWeight="600"
            >
              <HStack py={{ base: "0", md: "4" }}>
                <ShieldCheck fontSize={{ base: "12px", md: "16px" }} />
                <Text fontSize={{ base: "12px", md: "16px" }}>Challenges</Text>
              </HStack>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding="0">
              <>
                <PostFormSection
                  openModal={onOpenDiscussion}
                  setClickPhoto={setClickPhoto}
                  setSelectedComponent={setSelectedComponent}
                />
                <SuggestionSection
                  friendSuggestions={
                    suggestionData?.data?.data?.friendSuggestion
                  }
                  triggeredFrom="friend"
                  isLoading={suggestionLoading}
                />
                {/* <LatestVideosSlider /> */}
                <FeedPostList triggeredFrom="user" />
              </>
            </TabPanel>
            <TabPanel padding="0">
              <>
                <ChallengeForm
                  openModal={onOpenChallenge}
                  triggeredFrom="user"
                />
                <SuggestionSection />
                <ChallengeList triggeredFrom="user" />
              </>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default FeedTabsSection;

import { Image } from "@chakra-ui/image";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { Plus } from "lucide-react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getAllPartnerList } from "@/api/people/studyPartner";
import CreateStudyRoom from "../studyRoom/createStudyRoom";
import StudyPartnerModal from "../studyPartner/studyPartnerModal";

const StudyPartnerSection = () => {
  const [state, setState] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenStudyPartner,
    onOpen: onOpenStudyPartner,
    onClose: onCloseStudyPartner,
  } = useDisclosure();
  const { _id: uid } = useSelector((state) => state.userData);
  const { isLoading, data, isError, error, isPending, isSuccess } = useQuery({
    queryKey: ["getAllPartnerList", uid],
    queryFn: () => getAllPartnerList(uid),
    onError: (error, variables, context) => {},
    onSuccess: (res) => setState(res),
  });

  const partnerList = state?.data.data.partnerList || [];
  const displayList = partnerList.slice(0, 4);
  const remainingCount = partnerList.length - displayList.length;

  return (
    <>
      <CreateStudyRoom isOpen={isOpen} onClose={onClose} />
      <Flex
        className="mt-5 gap-2 text-xs font-semibold"
        wrap="wrap"
        align="center"
      >
        <Box
          padding="0"
          textAlign="center"
          height={{ base: "3em", md: "4em" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="1px solid #757575"
          rounded="md"
          width={{ base: "35%", md: "150px" }}
          onClick={onOpen}
          cursor="pointer"
        >
          <Plus />
          <Text fontSize={{ base: "xs", md: "sm" }}>Study Room</Text>
        </Box>
        <Box
          height={{ base: "3em", md: "4em" }}
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="1px solid #757575"
          rounded="md"
          width={{ base: "40%", md: "180px" }}
          cursor="pointer"
          onClick={onOpenStudyPartner}
        >
          <Text fontSize={{ base: "xs", md: "sm" }}>Find Study Partner</Text>
        </Box>
        <StudyPartnerModal
          isOpen={isOpenStudyPartner}
          onClose={onCloseStudyPartner}
        />
        {displayList.map((item, ind) => (
          <Image
            key={ind}
            cursor="pointer"
            width={{ base: "4em", md: "5em" }}
            height={{ base: "4em", md: "5em" }}
            alt="img"
            className="rounded-md"
            src={item?.profilePic}
            m={{ base: "1", md: "2" }}
          />
        ))}
        {remainingCount > 0 && (
          <Box
            width={{ base: "4em", md: "5em" }}
            height={{ base: "4em", md: "5em" }}
            textAlign="center"
            alignItems="center"
            display="flex"
            justifyContent="center"
            border="1px solid #757575"
            rounded="md"
            cursor="pointer"
            m={{ base: "1", md: "2" }}
          >
            <Text fontSize={{ base: "xs", md: "sm" }}>+{remainingCount}</Text>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default StudyPartnerSection;

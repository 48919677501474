import React from "react";
import { Box } from "@chakra-ui/react";
import MainHeader from "../mainHeader";
import HomeRightSidebar from "../rightSidebars/homeRightSidebar";
import MainLeftNavigation from "../leftNavigations/mainLeftnavigation";
const FeedsLayout = ({ children }) => {
  return (
    <Box marginLeft={{ sm: "0", lg: "2%" }} marginRight={{ sm: "0", lg: "2%" }}>
      <MainHeader />
      <div className="flex pt-8">
        <Box>
          <MainLeftNavigation />
        </Box>
        <Box overflow="hidden" flex="1" pl="17%" pr="17%">
          {children}
        </Box>
        <Box display={{ md: "block", sm: "none" }}>
          <HomeRightSidebar />
        </Box>
      </div>
    </Box>
  );
};

export default FeedsLayout;

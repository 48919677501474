import HomeChatModal from "@/components/chatSection/homeChatModal";
import FeedTabsSection from "@/components/home/feedTabsSection";
import GroupSection from "@/components/home/groupSection";
import EventSection from "@/components/home/eventSection";
import GetLocationAndDeviceInfo from "@/components/geoLocation&deviceInfo";
import StudyPartnerSection from "@/components/home/studyPartnerSection";
import { useEffect, useState } from "react";
import FeedsLayout from "@/components/layouts/feedsLayout";
import { socket } from "@/socket";
import { useSelector } from "react-redux";
import { requestPermission } from "@/firebase/fcm";
const Feed = () => {
  const [isConnected, setIsConnected] = useState(false);
  const { _id: uid } = useSelector((state) => state.userData);

  useEffect(() => {
    requestPermission();
  }, [uid]);
  useEffect(() => {
    socket.connect({ data: { uid } });
    function onConnect() {
      if (isConnected == false) {
        socket.emit("signin", { data: { uid } });
        socket.emit("joinEvent", {
          data: { eventId: "6622145f2629bf2e61123331" },
        });
        setIsConnected(true);
      }
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onWaitingList(value) {
      console.log("value:35", value);
    }

    function onClassRefresh(value) {
      console.log("value:39", value);
    }

    function onNewComment(value) {
      console.log("newComment:", value);
    }

    function onEventEnd(value) {
      console.log("value:43", value);
    }
    function onCommentUpdate(value) {
      console.log("commentRefresh:", value);
    }
    function onPostUpdate(value) {
      console.log("postRefresh:", value);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("waitingList", onWaitingList);
    socket.on("classRefresh", onClassRefresh);
    socket.on("eventEnd", onEventEnd);
    socket.on("commentRefresh", onCommentUpdate);
    socket.on("newComment", onNewComment);
    socket.on("postRefresh", onPostUpdate);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("waitingList", onWaitingList);
      socket.off("classRefresh", onClassRefresh);
      socket.off("eventEnd", onEventEnd);
      socket.off("commentRefresh", onCommentUpdate);
      socket.off("postRefresh", onPostUpdate);
    };
  }, []);
  return (
    <FeedsLayout>
      <GetLocationAndDeviceInfo />
      <EventSection />
      <GroupSection />
      <StudyPartnerSection />
      <FeedTabsSection />
      <HomeChatModal />
    </FeedsLayout>
  );
};

export default Feed;

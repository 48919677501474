import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import SwiperFunction from "./swiperFunction";

const StudyPartnerModal = ({ isOpen, onClose }) => {
  return (
    <Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent bgColor="white" height='60vh'  width="100%">
        <ModalHeader>Find study partner</ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%" position="relative" overflow='scroll'>
          <SwiperFunction />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StudyPartnerModal;

import request from "@/api/request";

let token;
if (typeof window !== "undefined") {
  token = window.localStorage.getItem("JWTToken");
} else {
  token = null;
}

//get friend list
export const getFriendList = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/friend/lists/${uid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

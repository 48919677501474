import request from "../request";

export const createChatGroup = async (uid, payload) => {
  try {
    const res = await request({
      url: `/chat/v1/group/create/${uid}`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getStudyRoomList = async (uid) => {
  try {
    const res = await request({
      url: `/chat/v1/group/list/user/${uid}`,
      type: "GET",
      headers: {
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getGroupChatDetail = async (uid, groupChatId) => {
  try {
    const res = await request({
      url: `/chat/v1/group/detail/${uid}/${groupChatId}`,
      type: "GET",
      headers: {
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getGroupAnalytics = async (groupId, uid) => {
  try {
    const res = await request({
      url: `/chat/v1/group/analytics/${groupId}/${uid}`,
      type: "GET",
      headers: {
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getGroupListDeatils = async (groupId, uid) => {
  try {
    const res = await request({
      url: `/chat/v1/group/list/${groupId}/${uid}`,
      type: "GET",
      headers: {
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

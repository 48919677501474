import { randomColors } from "@/utilities/commonFunctions";
import { Box, Card, Flex, HStack, Heading, Text } from "@chakra-ui/react";
import { ChevronRight, Star } from "lucide-react";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { BsStarFill } from "react-icons/bs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getGroups } from "@/api/feed/groups";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GroupSection = () => {
  const cardColors = ["bg-teal-600", "bg-blue-500", "bg-purple-500"];
  const router = useRouter();
  const [state, setState] = useState();
  const { _id: uid } = useSelector((state) => state.userData);
  const [currentColor, setCurrentColor] = useState("");

  const { isLoading, data, isError, error, isPending, isSuccess } = useQuery({
    queryKey: ["getGroups", uid],
    queryFn: () => getGroups(uid),
    onError: (error, variables, context) => {},
    // toast.error(`${error?.response?.data.error?.message}`, {
    //   position: toast.POSITION.TOP_RIGHT,
    // }),
    onSuccess: (res) => {
      console.log("home group test", res);
      setState(res.data.data.group);
    },
    // staleTime: Infinity,
    // cacheTime: Infinity,
  });
  const handleGroupDetail = (group) => {
    router.push(`/groups/${group._id}`);
  };

  return (
    <>
      <Flex align="center" justify="space-between" pt="4">
        <HStack>
          <Heading fontSize="18px" fontWeight="500">
            Groups
          </Heading>
        </HStack>
        <HStack
          onClick={() => {
            router.push("/groups");
          }}
          style={{ cursor: "pointer" }}
        >
          <Text fontWeight="500">View all</Text>
          <ChevronRight />
        </HStack>
      </Flex>
      <Box
        display="flex"
        justifyContent="start"
        overflowX="scroll"
        overflowY="hidden"
        gap="4"
        marginBottom="45"
      >
        {state?.map((item, ind) => (
          <div key={ind} className="flex" style={{ cursor: "pointer" }}>
            <Box
              bg={randomColors(["#336792", "#E56951", "#339287"])}
              position="relative"
              rounded="xl"
              w="220px"
              h="165px"
              mt="4"
              onClick={() => handleGroupDetail(item)}
            >
              <Text
                position="absolute"
                fontSize="14rem"
                fontWeight="900"
                letterSpacing="2"
                opacity="0.1"
                color="white.900"
                right="-6"
                top="-28"
              >
                &
              </Text>
              <Box
                color="#FCB461"
                position="absolute"
                left={4}
                top={5}
                fontSize="3xl"
              >
                {item?.groupType === "premium" ? (
                  <img src="/Premium.svg" />
                ) : (
                  <></>
                )}
              </Box>
              {/* <div className="absolute  right-5 top-3 text-2xl text-white">
                <ChevronRight />
              </div> */}
              <div className="absolute bottom-6 text-white text-2xl left-4 ">
                <p className="font-medium">
                  {item?.title}
                  <br />
                </p>
                <div className="mt-3">
                  <p className="text-base font-normal ">+{item?.posts} Posts</p>
                </div>
              </div>
              <div className="absolute text-white right-2 bottom-8 text-center">
                <p className="text-base p-0 m-0 font-semibold leading-none">
                  {item?.members}
                </p>
                <p className="text-xs font-semibold p-0 m-0 leading-none">
                  Members
                </p>
              </div>
            </Box>
          </div>
        ))}
      </Box>
    </>
  );
};

export default GroupSection;

import { Button, Card, CardBody, CardFooter, Flex, Text } from "@chakra-ui/react";
import React, { useState, useMemo, useRef } from "react";
import TinderCard from "react-tinder-card";

const db = [
  {
    name: "Richard Hendricks",
    url: "/profile.jpg",
  },
  {
    name: "Erlich Bachman",
    url: "/profile.jpg",
  },
  {
    name: "Monica Hall",
    url: "/profile.jpg",
  },
];

function SwiperFunction() {
  const [currentIndex, setCurrentIndex] = useState(db.length - 1);
  const [lastDirection, setLastDirection] = useState();
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0;

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < db.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  return (
    <div>
      {db.map((character, index) => (
        <TinderCard
          
          ref={childRefs[index]}
          className="swipe absolute w-[100%]"
          key={character.name}
          onSwipe={(dir) => swiped(dir, character.name, index)}
          onCardLeftScreen={() => outOfFrame(character.name, index)}
          preventSwipe={["up", "down"]}
        >
          <Card size="xl" width="530px" height="50vh" boxShadow="sm" p={3} rounded="lg" border="1px solid #e6e6e6">
            <CardBody
              width="530px"
              height="400px"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundImage: "url(/profile.jpeg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
                alignItems: "center",
                borderRadius: "10px",
                color: "#fff",
                fontSize: "20px",
              }}
            >
              <Text> {character.name}</Text>
            </CardBody>
            <CardFooter pt={3} width="100%">
              <Flex width="100%" gap={6} alignItems="center" justifyContent="space-between">
                <Button
                  width="100%"
                  style={{ backgroundColor: "#fff", color: "black", border: "1px solid #000" }}
                  onClick={() => swipe("left")}
                  disabled={!canSwipe}
                >
                  Ignore
                </Button>
                <Button
                  width="100%"
                  style={{ backgroundColor: "#000", color: "#FFF" }}
                  onClick={() => swipe("right")}
                  disabled={!canSwipe}
                >
                  Add
                </Button>
              </Flex>
            </CardFooter>
          </Card>
        </TinderCard>
      ))}
    </div>
  );
}

export default SwiperFunction;

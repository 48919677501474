import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("JWTToken");
    const UID = localStorage.getItem("uid");
    if (token && UID) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Uid = UID;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = ({ url, type = "GET", data, headers = {} }) => {
  return axiosInstance({ url, method: type, data, headers });
};

export default request;

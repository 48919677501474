import {
  Box,
  Text,
  Input,
  Flex,
  InputLeftElement,
  Button,
  Checkbox,
  Image,
  Stack,
  InputGroup,
  Spinner,
} from "@chakra-ui/react";
import { AlertCircle, X, Search } from "lucide-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { getExitingPartnerList } from "@/api/people/studyPartner";
import { createChatGroup } from "@/api/studyRoom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal } from "antd";
import { useRouter } from "next/router";

const CreateStudyRoom = ({ isOpen, onClose }) => {
  const { _id: uid } = useSelector((state) => state.userData);
  const [selectedItems, setSelectedItems] = useState([uid]);
  const [InfoState, setInfoState] = useState(false);
  const router = useRouter();

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const itemId = item._id;

      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((selectedItemId) => selectedItemId !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const {
    data: getPartner,
    isLoading: isPartnerLoading,
    isError: isPartnerError,
  } = useQuery(["getExitingPartnerList", uid], () => getExitingPartnerList(uid), {
    onError: (error) => {
      console.error("Error fetching partner list:", error);
    },
  });

  const createStudyRoomMutation = useMutation({
    mutationFn: (payload) => createChatGroup(uid, payload),
    onMutate: () => {
      console.log("Mutation is happening");
    },
    onError: (error, variables, context) => {
      console.error("Error creating study room:", error);
    },
    onSuccess: (res, variables, context) => {
      formik.resetForm();
      onClose();
      setSelectedItems([]);
      console.log("Study room created successfully:", res);
      router.push("/studyRoom");
    },
    onSettled: (data, error, variables, context) => {
      console.log("Mutation completed");
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      particpants: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Study Room Name is required"),
      description: Yup.string().required("Descriptor is required"),
    }),
    onSubmit: (values) => {
      createStudyRoomMutation.mutate({ ...values, particpants: selectedItems });
    },
  });

  const handleClose = () => {
    setInfoState(false);
    onClose();
  };

  return (
    <>
      <Modal
        width="460px"
        title={
          <Flex justifyContent="space-between">
            <span style={{ fontSize: "18px", fontWeight: "600" }}>Create Study Room</span>
            <span style={{ cursor: "pointer" }} onClick={handleClose}>
              <X />
            </span>
          </Flex>
        }
        centered
        open={isOpen}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        closable={false}
        footer={null}
      >
        {InfoState ? (
          <Text>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
          </Text>
        ) : (
          <>
            <Box className="p-1" w={"100%"}>
              <Text className="font-semibold mb-3">Study Room Name</Text>
              <Input
                placeholder="Study Room Name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name && (
                <Text fontSize={"small"} p={"4px"} color="red.500">
                  {formik.errors.name}
                </Text>
              )}
            </Box>
            <Box className="p-1 mt-2" w={"100%"}>
              <Text className="font-semibold mb-3">Add a Descriptor</Text>
              <Input
                placeholder="Add a Descriptor"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description && (
                <Text fontSize={"small"} p={"4px"} color="red.500">
                  {formik.errors.description}
                </Text>
              )}
            </Box>
            <Box className="p-1 mt-2" w={"100%"}>
              <Flex justifyContent={"space-between"}>
                <Text className="font-semibold mb-3">Invite Study Partners</Text>
                <AlertCircle onClick={() => setInfoState(true)} cursor="pointer" />
              </Flex>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Search />
                </InputLeftElement>
                <Input type="text" placeholder="Search" name="addRoom" />
              </InputGroup>
            </Box>
            <Box className="no-scrollbar p-1 m-2 h-[200px] rounded overflow-y-scroll">
              {getPartner?.data?.data?.acceptedData?.map((item, index) => (
                <Box className="flex mb-5" justifyContent="space-between" key={index}>
                  <Stack direction="row" align="center">
                    <Image rounded="lg" minW={"50px"} boxSize="60px" objectFit="cover" src={item.profilePic} />
                    <Stack direction={"column"} marginLeft={"13px"}>
                      <Text className="font-semibold">{item.name}</Text>
                      <Text className="text-sm text-slate-400 -mt-1">Study Partner</Text>
                    </Stack>
                  </Stack>
                  <Checkbox onChange={() => handleCheckboxChange(item)} />
                </Box>
              ))}
            </Box>
            <Button
              bgColor={"black"}
              w={"100%"}
              color={"white"}
              _hover={{ backgroundColor: "black" }}
              onClick={formik.handleSubmit}
              isLoading={createStudyRoomMutation.isLoading}
              spinner={<Spinner size="sm" />}
            >
              Create
            </Button>
            {createStudyRoomMutation.isError && (
              <Text color="red.500" mt={2}>
                Error creating study room. Please try again.
              </Text>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default CreateStudyRoom;

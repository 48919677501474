import { Box, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "lucide-react";
import React, { useState } from "react";
import { Input } from "antd";

const SearchInput = () => {
  const handleGlobalInput = () => {};
  return (
    <Box width="52%">
      <Input
        size="large"
        placeholder="Search here"
        prefix={<SearchIcon />}
        onClick={handleGlobalInput}
      />
    </Box>
  );
};

export default SearchInput;

import moment from "moment";
import { Text } from "@chakra-ui/react";
import { HTMLConverter } from "./commonFunctions";
//get Post time
export const getTimeAgo = (createdAt) => {
  const postCreationTime = moment(createdAt);
  return postCreationTime.fromNow();
};

export const getTimeUntil = (futureDate) => {
  console.log("10", futureDate);
  const futureMoment = moment(futureDate);
  const now = moment();
  if (futureMoment.isBefore(now)) {
    return "Event has already occurred";
  } else {
    const duration = moment.duration(futureMoment.diff(now));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `Starts in ${hours} hours and ${minutes} minutes until the event`;
  }
};

export const getDurationByStartTime = (startTime) => {
  console.log("10", startTime);
  const futureMoment = moment(startTime);
  const now = moment();

  const duration = moment.duration(futureMoment.diff(now));
  //const hours = duration.hours();
  //const minutes = duration.minutes();
  return duration;
};

export const getSecondsUntil = (futureDate) => {
  const futureMoment = moment(futureDate);
  const now = moment();
  if (futureMoment.isBefore(now)) {
    return 0;
  } else {
    const duration = moment.duration(futureMoment.diff(now));
    const seconds = duration.seconds();
    return seconds;
  }
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};
// readMore function
export const readMoreFunc = (
  showFullDescription,
  value,
  setShowFullDescription
) => {
  console.log(value);
  if (!value) {
    return;
  } else {
    if (value?.length > 20) {
      return (
        <>
          {showFullDescription ? (
            <HTMLConverter>{value}</HTMLConverter>
          ) : (
            <HTMLConverter>{value?.slice(0, 150)}...</HTMLConverter>
          )}
          {showFullDescription ? (
            <Text
              cursor={"pointer"}
              fontWeight={"700"}
              as={"span"}
              onClick={() => setShowFullDescription(false)}
            >
              read less
            </Text>
          ) : (
            <Text
              cursor={"pointer"}
              fontWeight={"700"}
              as={"span"}
              onClick={() => setShowFullDescription(true)}
            >
              read more
            </Text>
          )}
        </>
      );
    } else {
      return value;
    }
  }
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, "0")}h ago`;
};
// :${minutes
//   .toString()
//   .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
